import React from 'react';
import './footer.scss'

function Footer() {
  return (
    <footer className="App-footer">
    
    </footer>
  );
}

export {Footer};

